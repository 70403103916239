.sport-btn{
  >span{
    display: flex;
    flex-direction: row;
    gap: .25rem;
    align-items: center;
  }
}

.sports-1{
  background: linear-gradient(180deg, #5CB008 0%, #006E4D 100%);
}

.sports-2{
  background: linear-gradient(180deg, #3DB9FF 0%, #0049B5 100%);;
}